import {
  BrowserRouter as Router,
  BrowserRouter,
  Route,
  Switch,
} from "react-router-dom";
import Homepage from "./Customer/HomePage/Homepage";
import FoodListBoot from "./Customer/FoodListBoot/FoodListBoot";
// import CartPage from "./Customer/CartPage/CartPage";
// import Notfound from "./Comman/Notfound";
import OrderHistory from "./Customer/OrderHistory/OrderHistory";
import ContactUs from "./Customer/ContactUs/ContactUs";
import PaymentSuccess from "./Customer/Payment/PaymentSuccess";
import PaymentFaile from "./Customer/Payment/PaymentFailed";
import GiftSuccessPage from "./Customer/GiftCard/GiftSuccessPage";
import GiftFailpage from "./Customer/GiftCard/GiftFailpage";
import CODpage from "./Customer/CODPage/CODpage";
import React, { useEffect } from "react";
import NewFoodListPage from "./Customer/NewFoodListPage/NewFoodListPage";
import NewCartPage from "./Customer/NewCartPage/NewCartPage";
import PaymentSuccessDineIn from "./Customer/Payment/PaymentSuccessDineIn";
import PaymentFailedDineIn from "./Customer/Payment/PaymentFailedDineIn";
import DineInRedirectPage from "./Customer/DineInRedirectPage/DineInRedirectPage";
import CODPageDinein from "./Customer/CODPage/CODPageDinein";
import NewGiftCard from "./Customer/GiftCard/NewGiftCard";
import "./App.css"
import AnspaymentSuccess from "./Customer/Payment/ANS/anspaymentSuccess";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AnspaymentSuccessDinein from "./Customer/Payment/ANS/anspaymentSuccessDinein";
import GiftVoucherANZpayment from "./Customer/GiftCard/GiftVoucherANZpayment";
import RevampCardPage from "./Customer/NewCartPage/Revamp/RevampCardPage";
import RevampFoodlistPage from "./Customer/NewFoodListPage/RevampFoodlistPage";
import OrderDetailsPage from "./Customer/QRCODE/OrderDetailsPage";
import TableReservationpage from "./Customer/TableReservation/TableReservation/TableReservationpage";

function App() {
  var pathname = window.location.href;
  var pathnamesplits = pathname
    .replace(/(https?:\/\/)?(www.)?/i, "")
    .split("/");

  // const cart_ = String(pathnamesplits[2])

  const pay_ = String(pathnamesplits[5]);
  const pay_unicID_ = String(pathnamesplits[6]);
  const Voucher_id_ = String(pathnamesplits[3]);

  const location = useLocation();
  const pay = pay_?.split('#')[0];
  const pay_unicID = pay_unicID_?.split('#')[0];
  const Voucher_id = Voucher_id_?.split('#')[0];
  // const cartpage = Voucher_id_?.split('#')[0];


  // const myString = pay_unicID;
  // const extractedString = myString?.split('#')[0];

  // console.log("pay_unicID" ,pay,  pay_unicID ,Voucher_id)/

  // const loc = window?.location?.pathname?.split("/");

  useEffect(() => {
    const addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );

    document.body.appendChild(addScript);

    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement({
        pageLanguage: "en",
      }, "google_translate_element");
    };

    return () => {
      if (document.body.contains(addScript)) {
        document.body.removeChild(addScript);
      }
    };
  }, []);

  // const queryParams = new URLSearchParams(location.search);
  // const hostedCheckoutId = queryParams.get("hostedCheckoutId");
  // const RETURNMAC = queryParams.get("RETURNMAC");

  // console.log("ss" ,RETURNMAC ,hostedCheckoutId)


  return (
    <div className="App">
      <BrowserRouter>
        <Router>
          <Switch>
            <Route path={`/OrderHistory`} exact component={OrderHistory} />
            <Route path={`/GiftCard`} exact component={NewGiftCard} />
            <Route path={`/ContactUs`} exact component={ContactUs} />
            <Route exact path={`/success`} component={CODpage} />
            <Route exact path={`/success_dinein`} component={CODPageDinein} />
            <Route path={`/`} exact component={Homepage} />
            <Route
              exact
              path={`/:poId/:tableId/menu/anzpayment/${pay}/${pay_unicID}/`}
              // path={`/:poId/:tableId/menu/payment/${pay}/${pay_unicID}/?RETURNMAC=${RETURNMAC}&hostedCheckoutId=${hostedCheckoutId}`}
              component={AnspaymentSuccess}
            />
            <Route
              exact
              path={`/:poId/:tableId/menu/anzpaymentDinein/${pay}/`}
              component={AnspaymentSuccessDinein}
            />

            <Route
              exact
              path={`/:poId/:tableId/foodlist`}
              component={FoodListBoot}
            />
            <Route
              path={`/:poId/dineIn`}
              exact
              component={DineInRedirectPage}
            />
            {/* <Route exact path={`/:poId/menu`} component={NewFoodListPage} /> */}
            <Route exact path={`/:poId/menu`} component={RevampFoodlistPage} />
            {/* <Route exact path={`/:poId/menunew`} component={RevampFoodlistPage} /> */}


            {/* <Route exact path={`/:poId/cartpage`} component={NewCartPage} /> */}
            <Route exact path={`/:poId/cartpage`} component={RevampCardPage} />
            {/* <Route exact path={`/:poId/cartpagenew`} component={RevampCardPage} /> */}

            <Route exact path={`/order_details/:branchId/:uniquecode`} component={OrderDetailsPage} />
            <Route exact path={`/booktable`} component={TableReservationpage} />
            <Route exact path={`/booktable/:branchname/:branchId`} component={TableReservationpage} />




            <Route
              exact
              path={`/:poId/:tableId/menu/payment_success/${pay}`}
              component={PaymentSuccessDineIn}
            />
            <Route
              exact
              path={`/:poId/:tableId/menu/payment_success/${pay}/${pay_unicID}`}
              component={PaymentSuccess}
            />
            <Route
              exact
              path={`/:poId/:tableId/menu/payment_failed/${pay}`}
              component={PaymentFailedDineIn}
            />
            <Route
              exact
              path={`/:poId/:tableId/menu/payment_failed/${pay}/${pay_unicID}`}
              component={PaymentFaile}
            />
            <Route
              exact
              path={`/voucher/success/${Voucher_id}`}
              component={GiftSuccessPage}
            />

            <Route
              exact
              path={`/voucher/anzpayment/${Voucher_id}/`}
              component={GiftVoucherANZpayment}
            />
            <Route
              exact
              path={`/voucher/fail/${Voucher_id}`}
              component={GiftFailpage}
            />
            {/* <Route path="*" component={Notfound} /> */}
          </Switch>
        </Router>
      </BrowserRouter>
    </div>
  );
}

export default App;
